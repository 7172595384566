import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap'
import { clearStorage } from '../utils/turnTools'
import './GameCounter.scss'

class GameCounter extends Component {
  constructor() {
    super()
    this.state = {
      turn: JSON.parse(localStorage.getItem('turn')),
      gold: JSON.parse(localStorage.getItem('gold')),
      influence: JSON.parse(localStorage.getItem('influence')),
      goldPerTurn: JSON.parse(localStorage.getItem('goldPerTurn')),
      influencePerTurn: JSON.parse(localStorage.getItem('influencePerTurn')),
      endGame: false,
    }
  }

  endGame = () => {
    clearStorage()
    this.setState({ endGame: true })
  }

  turnChange = () => {
    const {
      turn,
      gold,
      goldPerTurn,
      influencePerTurn,
    } = this.state
    const newGold = (turn + 1) + goldPerTurn + gold
    const newInfluence = influencePerTurn
    const newTurn = turn + 1
    localStorage.setItem('gold', newGold)
    localStorage.setItem('influence', newInfluence)
    localStorage.setItem('turn', newTurn)
    return this.setState({
      gold: newGold,
      influence: newInfluence,
      turn: newTurn,
    })
  }

  adjustGold = (amount) => {
    const { gold } = this.state
    let newGold = gold + amount
    if (newGold < 0) newGold = 0
    localStorage.setItem('gold', newGold)
    return this.setState({ gold: newGold })
  }

  adjustInfluence = (amount) => {
    const { influence } = this.state
    let newInfluence = influence + amount
    if (newInfluence < 0) newInfluence = 0
    localStorage.setItem('influence', newInfluence)
    return this.setState({ influence: newInfluence })
  }

  adjustGoldPerTurn = (amount) => {
    const { goldPerTurn } = this.state
    let newGoldPerTurn = goldPerTurn + amount
    if (newGoldPerTurn < 0) newGoldPerTurn = 0
    localStorage.setItem('goldPerTurn', newGoldPerTurn)
    return this.setState({ goldPerTurn: newGoldPerTurn })
  }

  adjustInfluencePerTurn = (amount) => {
    const { influencePerTurn } = this.state
    let newInfluencePerTurn = influencePerTurn + amount
    if (newInfluencePerTurn < 0) newInfluencePerTurn = 0
    localStorage.setItem('influencePerTurn', influencePerTurn)
    return this.setState({ influencePerTurn: newInfluencePerTurn })
  }

  render() {
    const {
      turn,
      gold,
      influence,
      goldPerTurn,
      influencePerTurn,
      endGame,
    } = this.state
    if (endGame === true) {
      return <Redirect to="/" />
    }
    return (
      <Container>
        <div className="text-white game-counter">
          <Row className="game-counter--header-turn text-center">
            <Col xs="8">
              <h2>
                Current Turn: {turn}
              </h2>
            </Col>
            <Col xs="4">
              <Button color="primary" onClick={() => this.endGame()}>End Game</Button>
            </Col>
          </Row>
          <hr />
          <Row className="text-center game-counter--header">
            <hr />
            <Col xs="6">
              <Button outline color="primary" onClick={() => this.adjustGold(1)}>+</Button>
              <h3 className="game-counter--header-clickable">
                Gold
                <br />
                {gold}
              </h3>
              <Button outline color="primary" onClick={() => this.adjustGold(-1)}>-</Button>
            </Col>
            <Col xs="6">
              <Button outline color="primary" onClick={() => this.adjustInfluence(1)}>+</Button>
              <h3 className="game-counter--header-clickable">
                Influence
                <br />
                {influence}
              </h3>
              <Button outline color="primary" onClick={() => this.adjustInfluence(-1)}>-</Button>
            </Col>
          </Row>
          <hr />
          <Row className="text-center game-counter--header-perturn">
            <Col xs="2">
              <Button outline color="primary" onClick={() => this.adjustGoldPerTurn(-1)}>-</Button>
            </Col>
            <Col xs="8">
              Gold per Turn: {goldPerTurn}
            </Col>
            <Col xs="2">
              <Button outline color="primary" onClick={() => this.adjustGoldPerTurn(1)}>+</Button>
            </Col>
          </Row>
          <Row className="text-center game-counter--header-perturn">
            <Col xs="2">
              <Button outline color="primary" onClick={() => this.adjustInfluencePerTurn(-1)}>-</Button>
            </Col>
            <Col xs="8">
              Influence per Turn: {influencePerTurn}
            </Col>
            <Col xs="2">
              <Button outline color="primary" onClick={() => this.adjustInfluencePerTurn(1)}>+</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button block color="primary" onClick={() => this.turnChange()}>Next Turn</Button>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
}

export default GameCounter
