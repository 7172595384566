import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { startGame } from '../utils/turnTools'
import './StartGame.scss'

class StartGame extends Component {
  constructor() {
    super()
    this.state = {
      turn: JSON.parse(localStorage.getItem('turn')),
      playGame: false,
    }
  }

  beginGame = () => {
    startGame()
    this.setState({ playGame: true })
  }

  continueGame = () => {
    this.setState({ playGame: true })
  }

  render() {
    const { turn, playGame } = this.state
    if (playGame === true) {
      return <Redirect to="/counter" />
    }
    return (
      <div className="start-game text-center">
        <h1>monarch.gg</h1>
        {(turn && turn > 0) && <Button onClick={() => this.continueGame()} className="start-game--button" color="primary">Continue Game</Button>}
        {(!turn || turn === 0) && (
          <Button
            className="start-game--button"
            color="primary"
            onClick={() => this.beginGame()}
          >
            New Game
          </Button>
        )}
      </div>
    )
  }
}

export default StartGame
