import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import StartGame from './StartGame/StartGame'
import GameCounter from './GameCounter/GameCounter'

const Routes = () => (
  <div>
    <Route exact path="/" component={StartGame} />
    <Route exact path="/counter" component={GameCounter} />
  </div>
)

export default withRouter(Routes)
