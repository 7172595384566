export const clearStorage = () => {
  localStorage.setItem('turn', 0)
  localStorage.setItem('gold', 0)
  localStorage.setItem('influence', 0)
  localStorage.setItem('goldPerTurn', 0)
  localStorage.setItem('influencePerTurn', 0)
}

export const startGame = () => {
  clearStorage()
  localStorage.setItem('turn', 1)
  localStorage.setItem('gold', 1)
}
